/* Variable names from http://chir.ag/projects/name-that-color */
/*
* NAVIGATION LINKS // generic navigation links styling (header, footer, etc)
*/
.category-tile {
  position: relative;
}
.category-tile h1,
.category-tile h2,
.category-tile h3 {
  font-size: 1.75rem;
  position: absolute;
  bottom: 1.875rem;
  left: 1.875rem;
  color: #fff;
}
.category-tile::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
}

.category-tile {
  display: block;
  position: relative;
  text-align: center;
  text-decoration: none;
}
.category-tile:hover {
  text-decoration: none;
}
@media (min-width: 768px) {
  .category-tile.zoom .image-wrapper {
    will-change: transform;
    transition: transform 200ms ease-out;
  }
  .category-tile.zoom:hover .image-wrapper {
    transform: scale(1.2);
  }
}
.category-tile .image-cropper {
  border-radius: 0;
  overflow: hidden;
  margin: auto;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}
.category-tile .image-wrapper {
  width: 100%;
  position: relative;
}
.category-tile .image-wrapper .background-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.category-tile .image-wrapper-custom .background-image-tablet,
.category-tile .image-wrapper-custom .background-image-desktop {
  display: none;
}
@media (min-width: 768px) {
  .category-tile .image-wrapper-custom .background-image-mobile,
  .category-tile .image-wrapper-custom .background-image-desktop {
    display: none;
  }
  .category-tile .image-wrapper-custom .background-image-tablet {
    display: block;
  }
}
@media (min-width: 992px) {
  .category-tile .image-wrapper-custom .background-image-mobile,
  .category-tile .image-wrapper-custom .background-image-tablet {
    display: none;
  }
  .category-tile .image-wrapper-custom .background-image-desktop {
    display: block;
  }
}
.category-tile .image-wrapper .video-container .video-aspect-ratio {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  padding-top: 56.25%;
  padding-left: 177.7777777778vh;
  width: 0;
  height: 0;
  min-height: 100%;
  min-width: 100%;
}
.category-tile .image-wrapper .video-container .video-aspect-ratio .video-player {
  position: static;
  pointer-events: none;
}
.category-tile .image-wrapper .video-container .video-aspect-ratio iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.category-tile .image-wrapper .video-container .video-aspect-ratio iframe.video-player {
  position: absolute;
}
.category-tile .image-text-block-text .primary-text:not([class*=p--]):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6) {
  font-size: 16px;
}
.category-tile .image-text-block-text .secondary-text:not([class*=p--]):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6) {
  color: orange;
  font-size: 13px;
}
.category-tile.text-placement-below .category-tile-text {
  margin-top: 1rem;
}
.category-tile.text-placement-inside::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 0;
}
.category-tile.text-placement-inside .category-tile-text {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  color: #fff;
  z-index: 1;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
}
.category-tile.aspect-ratio-square .image-wrapper::after {
  display: block;
  content: "";
  clear: both;
}
.category-tile.aspect-ratio-square .image-wrapper::before {
  content: "";
  display: block;
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 100%;
}
.category-tile.aspect-ratio-landscape .image-wrapper::after {
  display: block;
  content: "";
  clear: both;
}
.category-tile.aspect-ratio-landscape .image-wrapper::before {
  content: "";
  display: block;
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 66.6666666667%;
}
.category-tile.aspect-ratio-portrait .image-wrapper::after {
  display: block;
  content: "";
  clear: both;
}
.category-tile.aspect-ratio-portrait .image-wrapper::before {
  content: "";
  display: block;
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 150%;
}

.compare-bar-wrapper {
  bottom: 0;
  display: none;
  position: fixed;
  width: 100%;
  z-index: 1;
  background-color: rgba(34, 34, 34, 0.65);
}
.compare-bar-wrapper .product-slots {
  height: 6.25em;
}
.compare-bar-wrapper .slot {
  background-color: #fff;
  height: 5em;
  margin: 0 auto;
  position: relative;
  width: 6.788em;
  border-radius: 3px;
}
.compare-bar-wrapper .slot:not([data-pid]) {
  border: 1px dashed #353535;
}
.compare-bar-wrapper .slot img {
  float: left;
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 3px 0 0 3px;
}
.compare-bar-wrapper .slot .close {
  width: 25%;
  border-radius: 0 3px 3px 0;
  opacity: 1;
  position: absolute;
  top: 0;
  right: -1px;
  bottom: 0;
  left: 70%;
}
.compare-bar-wrapper .slot .close button::before {
  content: "";
  display: block;
  background: #f6f6f6 url("../images/svg-icons/icon-close.svg") no-repeat center center;
  background-size: 10px 10px;
  height: 100%;
  width: 16px;
  position: absolute;
  right: -1px;
  left: 70%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.compare-bar-wrapper .slot .action {
  text-align: center;
}
.compare-bar-wrapper .clear-all {
  color: #fff;
}

.min-products-msg {
  transform: translate(-50%, -50%);
  position: fixed;
  top: 15%;
  left: 50%;
  animation: fade 5s linear forwards;
  box-shadow: 1px 1px 5px grey;
  padding: 1em;
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.min-products-msg.show {
  display: block;
}

.product-comparison .back-to-results {
  margin: 0.75rem 0;
}
.product-comparison .product-header > .col {
  padding: 0;
}

.table-striped-column td:nth-of-type(odd) {
  background-color: #eee;
}

.compare-table {
  margin-bottom: 2.5rem;
}
.compare-table thead tr:hover {
  background: none;
}

.performance__table td.label::before {
  content: "";
  display: inline-block;
  height: 15px;
  width: 15px;
  margin-right: 0.75rem;
  position: relative;
  top: 2px;
}
.performance__table td.label.label--blue::before {
  background: #4a90e2;
}
.performance__table td.label.label--orange::before {
  background: #f5a623;
}
.performance__table td.label.label--purple::before {
  background: #6e3f98;
}
.performance__table td.label.label--light-green::before {
  background: #70a730;
}

.yotpo .yotpo-score {
  padding-left: 10px;
}
.yotpo .yotpo-score span {
  font-size: 14px;
  font-weight: 400;
  color: #000;
}

.refinement-bar {
  overflow: auto;
}
.refinement-bar h4 {
  margin-bottom: 8px;
}
.refinement-bar ul {
  padding-left: 0;
}
@media (max-width: 767.98px) {
  .refinement-bar {
    background-color: #fff;
    box-shadow: 0 0.313em 0.938em rgba(0, 0, 0, 0.5);
    display: none;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
  }
}
@media (max-width: 575.98px) {
  .refinement-bar {
    position: fixed;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .refinement-bar {
    display: block !important;
    margin-top: -34px;
  }
}

.filter-header {
  margin-left: -12px;
  margin-right: -12px;
}

.header-bar button.close {
  font-size: 1rem;
  font-weight: normal;
  opacity: 1;
  width: 1rem;
  position: relative;
  right: 10px;
  top: 10px;
}
.header-bar button.close .icon {
  height: 16px;
  width: 16px;
  pointer-events: none;
}

.refinements .header,
.refinements .values {
  padding: 0.313em 0;
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .refinements .header li,
  .refinements .values li {
    display: inline-block;
  }
}
.refinements .header {
  font-size: 1.125em;
  color: #888380;
}
.refinements ul li {
  padding-left: 1px;
}
.refinements ul li button {
  border: none;
  cursor: pointer;
  color: #222;
  background-color: transparent;
  text-align: left;
}
@media (min-width: 768px) {
  .refinements ul li button {
    width: 100%;
  }
}
.refinements li {
  list-style-type: none;
  padding-left: 0;
}
.refinements li.color-attribute {
  display: inline-block;
  padding-left: 0;
}
.refinements li.color-attribute button {
  padding: 0;
}
.refinements li.disabled {
  opacity: 0.5;
}
.refinements .card {
  min-width: 0;
}
.refinements .card .card-body ul li {
  border-top: none;
}
@media (max-width: 575.98px) {
  .refinements {
    padding: 0;
  }
}
.refinements .card-header h2 {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  margin-bottom: 0;
}

.secondary-bar {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 1em;
  overflow: auto;
}
.secondary-bar button.reset {
  margin: 0 auto;
}
@media (min-width: 768px) {
  .secondary-bar button.reset {
    width: 100%;
    margin-top: 0.75rem;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .secondary-bar {
    padding: 0.938em 2.813em;
  }
}
@media (max-width: 575.98px) {
  .secondary-bar {
    padding: 0.938em;
  }
}

.refinement-header {
  overflow: auto;
}

.disabled {
  pointer-events: none;
}

.grid-header,
.content-grid-header {
  margin-top: 1em;
  margin-bottom: 9px;
}
@media (max-width: 575.98px) {
  .grid-header .result-count,
  .content-grid-header .result-count {
    padding-bottom: 0.938em;
  }
  .grid-header .filter-results,
  .content-grid-header .filter-results {
    display: block;
    width: 100%;
  }
}
.grid-header select,
.content-grid-header select {
  width: 100%;
}

.result-count p {
  margin: 0;
}
.result-count .result-found {
  position: absolute;
  bottom: 0;
}
@media (min-width: 768px) {
  .result-count {
    padding: 0 20px;
  }
}

@media (min-width: 768px) {
  .sort-options {
    padding: 0 20px;
  }
}

.refinement {
  margin-bottom: 1em;
}
@media (max-width: 767.98px) {
  .refinement.card {
    border-top: 0 none;
    border-left: 0 none;
    border-right: 0 none;
    border-radius: 0;
    margin-bottom: 0;
  }
  .refinement .card-header {
    border-bottom: 0 none;
    padding: 0.25rem 1rem;
  }
  .refinement .card-body {
    padding: 0 1rem;
  }
}
.refinement a {
  text-decoration: none;
}
.refinement:first-of-type.refinement-category {
  padding: 17px 0;
}
.refinement:first-of-type.refinement-category > ul > li {
  margin-left: 0;
}
.refinement.refinement-category ul {
  margin-bottom: -5px;
}
.refinement.refinement-category li {
  display: block;
}
.refinement.refinement-category li:nth-of-type(n+2) a {
  margin-top: 18px;
  display: block;
}
.refinement.refinement-category li ul li {
  margin-top: 8px;
}
.refinement.refinement-color button img {
  border: 1px solid #353535;
  border-color: #fff;
}
.refinement.refinement-color button img.selected {
  border-color: #353535;
}
.refinement.refinement-color button img:hover {
  border-color: #353535;
}
.refinement.refinement-color button[data-attr-value=swatch-circle-white] {
  border: 1px solid #d7d3d1;
}

.search-keywords {
  font-weight: bold;
}

.filter-bar ul {
  padding-left: 12px;
  display: flex;
  flex-wrap: wrap;
}
.filter-bar li {
  list-style: none;
  float: left;
  margin-bottom: 0.3125em;
  margin-right: 0.3125em;
  margin-top: 0;
}
.filter-bar li:first-child {
  margin-left: 0;
}
.filter-bar li.filter-value {
  border: 1px solid #d7d3d1;
  padding: 0.3125em;
  border-radius: 0;
  position: relative;
}
.filter-bar li.filter-value button {
  color: #222;
  border: none;
  width: auto;
  padding: 0 2rem 0 0.5rem;
}
.filter-bar li.filter-value button::after {
  content: "";
  background: url("../images/svg-icons/icon-close.svg");
  background-size: 9px 9px;
  text-rendering: auto;
  position: absolute;
  right: 10px;
  top: 13px;
  height: 9px;
  width: 9px;
}
.filter-bar li.swatch-filter {
  font-size: 1.38em;
  position: relative;
  margin-top: 4px;
}
.filter-bar li.swatch-filter button {
  width: 100%;
  height: 100%;
  display: block;
  border: none;
}
.filter-bar li.swatch-filter button::before {
  content: "";
  background-color: white;
  width: 0.8em;
  height: 0.8em;
  border-radius: 0.4em;
  position: absolute;
  left: 0.5em;
  top: 0.5em;
}
.filter-bar li.swatch-filter button::after {
  content: "\f057";
  font: normal normal normal 14px/1 FontAwesome;
  display: inline;
  text-rendering: auto;
  position: absolute;
  left: 0.18em;
  top: 0.12em;
  font-size: 1.5em;
}

@media (max-width: 575.98px) {
  .tab-content {
    padding-left: 0;
    padding-right: 0;
  }
}

.product-grid {
  padding: 0 20px;
}
.product-grid > div {
  border-bottom: 1px solid #d7d3d1;
  padding: 20px 0;
}
.product-grid > div:first-of-type {
  border-top: 1px solid #d7d3d1;
}
.product-grid > div > div {
  height: 40%;
}
@media (min-width: 576px) {
  .product-grid > div:nth-of-type(2) {
    border-top: 1px solid #d7d3d1;
  }
  .product-grid > div:nth-of-type(odd) .product-tile {
    border-right: 1px solid #d7d3d1;
  }
}
@media (min-width: 992px) {
  .product-grid > div:nth-of-type(odd) .product-tile {
    border-right: none;
  }
  .product-grid > div:nth-of-type(3) {
    border-top: 1px solid #d7d3d1;
  }
  .product-grid > div:nth-of-type(3n + 1) .product-tile {
    border-right: 1px solid #d7d3d1;
  }
  .product-grid > div:nth-of-type(3n) .product-tile {
    border-left: 1px solid #d7d3d1;
  }
}

.search-tips {
  text-align: left;
  margin-top: 1.875rem;
}

.category-item {
  margin-bottom: 0.938rem;
}

.show-more,
.show-more-content {
  padding: 0.938em;
  clear: both;
}

.search-nav .nav-tabs-wrapper {
  padding: 0;
  margin: 1.25rem;
}
.search-nav .nav-tabs-wrapper .nav-tabs {
  border-bottom: 0 none;
}

.product-options .quantity {
  position: absolute;
  bottom: 0;
  right: 0;
}